import React, { useEffect } from 'react'
import SimpleHeader from '../components/Layout/SimpleHeader'
import { NoIndex } from '../components/NoIndex'
import HighRisk from '../components/Quiz/components/HighRisk'
import useIsGoodpather from '../utils/useIsGoodpather'
import { trackUserAction, PushToDatalayer } from '../utils/analytics-tracking'
import CountryProvider from '../components/common/CountryProvider'

const HighRiskWrapper = ({ location }) => {
  const isGoodpather = useIsGoodpather()
  const { conditionSlug, riskLevel } = location?.state || {}

  useEffect(() => {
    if (!riskLevel) return

    // Track risk level to GA
    PushToDatalayer({
      event: 'gtm.risk-level',
      data: riskLevel
    })

    // Track high risk triaged
    trackUserAction('High Risk Triaged', {
      riskLevel: riskLevel,
      condition: conditionSlug
    })
  }, [conditionSlug, riskLevel])
  

  return (
    <>
      <NoIndex />
      <SimpleHeader disableNavigation={!isGoodpather} />
      <CountryProvider>
        <div className="max-w-xl mx-auto">
          <HighRisk state={location.state} />
        </div>
      </CountryProvider>
    </>
  )
}

export default HighRiskWrapper
