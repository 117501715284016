import React, { memo, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import RichText from '../../../utils/richTextUtils'
import { BLOCKS } from '@contentful/rich-text-types'
import { useEmployerContext } from '../../../contexts/Employer/employerProvider'
import { useCountryContext } from '../../common/CountryProvider'
import { useLocalizedContent } from '../../../utils/localization/useLocalizedContent'
function HighRisk({ state }: Props) {
  const { conditionSlug, riskLevel } = state || {}
  const data = useStaticQuery(query)
  const { slug } = useEmployerContext()

  const {
    content: { defaultContent, mentalHealthLevel4Content, employers }
  } = useLocalizedContent<any>(data)

  const { currentCountry } = useCountryContext()

  const content = useMemo(() => {
    if (conditionSlug === 'mental-health' && riskLevel === 4) {
      return {
        ...mentalHealthLevel4Content?.nodes[0],
        ...(currentCountry?.emergencyTriageContent && {
          content: currentCountry.emergencyTriageContent
        })
      }
    }
    return defaultContent?.nodes[0]
  }, [
    conditionSlug,
    currentCountry?.emergencyTriageContent,
    defaultContent,
    mentalHealthLevel4Content,
    riskLevel
  ])

  const richTextDocument = useMemo(() => {
    if (content.readableId === 'high-risk-triage') {
      const employerContent = employers?.nodes?.find(
        (employer: any) => employer.slug === slug
      )
      if (employerContent?.highRiskContent?.json) {
        return employerContent.highRiskContent.json
      }
    }

    return content.content.json
  }, [content.content.json, content.readableId, employers, slug])

  return (
    <div className="px-6">
      <h2 className="w-full mt-4 mb-2 text-2xl font-bold leading-tight md:mt-0 md:text-4xl md:pt-8 md:px-4 md:text-center text-gp-grey-90 md:mb-4">
        {content.pageTitle}
      </h2>
      <p>
        <RichText
          richTextDocument={richTextDocument}
          options={{
            [BLOCKS.PARAGRAPH]: {
              options: {
                className:
                  'mx-auto text-base leading-normal text-gp-grey-70 md:text-xl'
              }
            }
          }}
        />
      </p>
    </div>
  )
}

export default memo(HighRisk)

type Props = {
  state: any
}

export const query = graphql`
  query HighRiskQuery {
    defaultContent: allContentfulEvergreenContent(
      filter: { readableId: { eq: "high-risk-triage" } }
    ) {
      nodes {
        readableId
        node_locale
        pageTitle
        content {
          json
        }
      }
    }
    mentalHealthLevel4Content: allContentfulEvergreenContent(
      filter: { readableId: { eq: "mh-high-risk-triage-level4" } }
    ) {
      nodes {
        readableId
        node_locale
        pageTitle
        content {
          json
        }
      }
    }
    employers: allContentfulEmployer {
      nodes {
        node_locale
        slug
        highRiskContent {
          json
        }
      }
    }
  }
`
